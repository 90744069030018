export function blockScrollBar(action) {
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    const headerWrapper = document.querySelector('#headerWrapper');

    if (action === 'lock' && headerWrapper) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollBarWidth}px`;
      headerWrapper.style.paddingRight = `${scrollBarWidth}px`;
      return;
    } 
    if (action === 'unlock' && headerWrapper) {
      document.body.style.overflow = "";
      document.body.style.paddingRight = `0px`;
      headerWrapper.style.paddingRight = `0px`;
      return;
    } 
  }
