import React from 'react';
import styles from './privacypolicy.module.css';

export function PrivacyPolicy() {
  return (
    <div>
      <h1 className={styles.privacyPolicyTitle}>Политика конфиденциальности</h1>
      <ol className={styles.mainList}>
        <li className={styles.mainOrderedItem}>
          <span>Общие положения</span>
          <ol className={styles.nestedList}>
            <li className={styles.nestedOrderedItem}>Настоящий документ определяет политику (далее — Политика) ООО «РОКС» (далее по тексту — Компания), в отношении обработки персональных данных.</li>
            <li className={styles.nestedOrderedItem}>Настоящая Политика разработана и утверждена в соответствии с требованиями статьи 18.1 Федерального закона от 27.07.2006 г. №152-ФЗ «О персональных данных» и действует в отношении всех персональных данных, обрабатываемых в Компании.</li>
            <li className={styles.nestedOrderedItem}>Целью настоящей Политики является обеспечение защиты прав и свобод человека и гражданина при обработке его персональных данных, а также интересов Компании.</li>
            <li className={styles.nestedOrderedItem}>Настоящая Политика определяет цели, принципы и условия обработки персональных данных сотрудников и иных лиц, чьи персональные данные обрабатываются Компанией, а также включает перечень мер, применяемых в целях обеспечения безопасности персональных данных при их обработке.</li>
          </ol>
        </li>
        <li className={styles.mainOrderedItem}>
          <span>Основные понятия, используемые в Политике</span>
          <ol className={styles.nestedList}>
            <li className={styles.nestedOrderedItem}>Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.</li>
            <li className={styles.nestedOrderedItem}>Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</li>
            <li className={styles.nestedOrderedItem}>Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://monit.roksperm.ru.</li>
            <li className={styles.nestedOrderedItem}>Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.</li>
            <li className={styles.nestedOrderedItem}>Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.</li>
            <li className={styles.nestedOrderedItem}>Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</li>
            <li className={styles.nestedOrderedItem}>Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</li>
            <li className={styles.nestedOrderedItem}>Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://monit.roksperm.ru</li>
            <li className={styles.nestedOrderedItem}>Персональные данные, разрешенные субъектом персональных данных для распространения, — персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее — персональные данные, разрешенные для распространения).</li>
            <li className={styles.nestedOrderedItem}>Пользователь — любой посетитель веб-сайта https://monit.roksperm.ru.</li>
            <li className={styles.nestedOrderedItem}>Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</li>
            <li className={styles.nestedOrderedItem}>Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.</li>
            <li className={styles.nestedOrderedItem}>Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.</li>
            <li className={styles.nestedOrderedItem}>Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и/или уничтожаются материальные носители персональных данных.</li>
          </ol>
        </li>
        <li className={styles.mainOrderedItem}>
          <span>Цели обработки персональных данных</span>
          <ol className={styles.nestedList}>
            <li className={styles.nestedOrderedItem}>
              <span>Компания осуществляет обработку персональных данных в целях:</span>
              <ul className={styles.nestedUnorderedList}>
                <li className={styles.nestedUnorderedItem}> обеспечения соблюдения Конституции Российской Федерации, законодательных и иных нормативных правовых актов Российской Федерации, локальных нормативных актов Компании;</li>
                <li className={styles.nestedUnorderedItem}> заключения с субъектом персональных данных любых договоров и соглашений, и их дальнейшего исполнения;</li>
                <li className={styles.nestedUnorderedItem}> предоставления информации о Компании, ее услугах, акциях и мероприятиях;</li>
                <li className={styles.nestedUnorderedItem}> коммуникации с субъектом персональных данных;</li>
                <li className={styles.nestedUnorderedItem}> направления субъекту персональных данных новостных материалов;</li>
                <li className={styles.nestedUnorderedItem}> обеспечения функционирования, безопасности и улучшения качества сайтов Компании;</li>
                <li className={styles.nestedUnorderedItem}> а также других целей, достижение которых не запрещено федеральным законодательством, международными договорами Российской Федерации.</li>
              </ul>
            </li>
          </ol>
        </li>
        <li className={styles.mainOrderedItem}>
          <span>Классификация персональных данных и категории субъектов, персональные данные которых обрабатываются в компании</span>
          <ol className={styles.nestedList}>
            <li className={styles.nestedOrderedItem}>К персональным данным относится любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных), обрабатываемая Компанией для достижения указанных целей.</li>
            <li className={styles.nestedOrderedItem}>Компания не осуществляет обработку специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных и философских убеждений, состояния здоровья, интимной жизни, судимости физических лиц, если иное не установлено законодательством Российской Федерации.</li>
            <li className={styles.nestedOrderedItem}>
              <span>Компания осуществляет обработку персональных данных следующих категорий субъектов персональных данных:</span>
              <ul className={styles.nestedUnorderedList}>
                <li className={styles.nestedUnorderedItem}> физические лица, выразившие намерение заключить с Компанией любые договоры и соглашения;</li>
                <li className={styles.nestedUnorderedItem}> физические лица, заключившие с Компанией любые договоры и соглашения;</li>
                <li className={styles.nestedUnorderedItem}> физические лица, персональные данные которых сделаны ими общедоступными, а их обработка не нарушает их прав и соответствует требованиям, установленным законодательством о персональных данных;</li>
                <li className={styles.nestedUnorderedItem}> иные физические лица, выразившие согласие на обработку Компанией их персональных данных или обработка персональных данных которых необходима Компании для выполнения обязанностей, исполнения функций или полномочий, возложенных и/или предусмотренных международным договором или законом Российской Федерации.</li>
              </ul>
            </li>
          </ol>
        </li>
        <li className={styles.mainOrderedItem}>
          <span>Основные принципы обработки персональных данных</span>
          <ol className={styles.nestedList}>
            <li className={styles.nestedOrderedItem}>
              <span>Обработка персональных данных в Компании осуществляется на основе следующих принципов:</span>
              <ul className={styles.nestedUnorderedList}>
                <li className={styles.nestedUnorderedItem}> законности целей и способов обработки персональных данных;</li>
                <li className={styles.nestedUnorderedItem}> соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных;</li>
                <li className={styles.nestedUnorderedItem}> соответствия состава и объема обрабатываемых персональных, а также способов обработки персональных данных заявленным целям обработки;</li>
                <li className={styles.nestedUnorderedItem}> достоверности персональных данных, их достаточности для целей обработки, недопустимости обработки персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;</li>
                <li className={styles.nestedUnorderedItem}> недопустимости обработки персональных данных, несовместимых с целями сбора персональных данных;</li>
                <li className={styles.nestedUnorderedItem}> недопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместных между собой;</li>
                <li className={styles.nestedUnorderedItem}> обеспечения хранения персональных данных не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных;</li>
                <li className={styles.nestedUnorderedItem}> уничтожения или обезличивания персональных данных по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено законодательством Российской Федерации, договором, стороной которого, выгодоприобретателем или поручителем, по которому является Субъект персональных данных;</li>
                <li className={styles.nestedUnorderedItem}> обеспечения конфиденциальности и безопасности обрабатываемых персональных данных.</li>
              </ul>
            </li>
          </ol>
        </li>
        <li className={styles.mainOrderedItem}>
          <span>Условия обработки персональных данных</span>
          <ol className={styles.nestedList}>
            <li className={styles.nestedOrderedItem}>Обработка персональных данных осуществляется с соблюдением принципов и правил, установленных Федеральным законом от 27 июля 2006 г. № 152-ФЗ «О персональных данных».</li>
            <li className={styles.nestedOrderedItem}>Компания осуществляет обработку персональных данных, как с использованием средств автоматизации, так и без использования средств автоматизации.</li>
            <li className={styles.nestedOrderedItem}>Компания может включать персональные данные субъектов в общедоступные источники персональных данных, при этом Компания берет письменное согласие субъекта на обработку его персональных данных.</li>
            <li className={styles.nestedOrderedItem}>Компания не осуществляет трансграничную передачу персональных данных.</li>
            <li className={styles.nestedOrderedItem}>Принятие на основании исключительно автоматизированной обработки персональных данных решений, порождающих юридические последствия в отношении субъекта персональных данных или иным образом затрагивающих его права и законные интересы, не осуществляется.</li>
            <li className={styles.nestedOrderedItem}>При отсутствии необходимости письменного согласия субъекта на обработку его персональных данных согласие субъекта может быть дано субъектом персональных данных или его представителем в любой позволяющей получить факт его получения форме.</li>
            <li className={styles.nestedOrderedItem}>Компания вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено федеральным законом, на основании заключаемого с этим лицом договора (далее — поручение оператора). При этом Компания обязывает лицо, осуществляющее обработку персональных данных по поручению Компании, соблюдать принципы и правила обработки персональных данных, предусмотренные Федеральным законом от 27 июля 2006 г. № 152-ФЗ «О персональных данных».</li>
            <li className={styles.nestedOrderedItem}>Предоставление доступа органам государственной власти (в том числе, контролирующих, надзорных, правоохранительных и иных органов) к персональным данным, обрабатываемым Компанией осуществляется в объёме и порядке, установленным соответствующим законодательством Российской Федерации.</li>
          </ol>
        </li>
        <li className={styles.mainOrderedItem}>
          <span>Права и обязанности субъекта персональных данных</span>
          <ol className={styles.nestedList}>
            <li className={styles.nestedOrderedItem}>
              <span>Субъект персональных данных имеет право:</span>
              <ul className={styles.nestedUnorderedList}>
                <li className={styles.nestedUnorderedItem}> получать информацию, касающуюся обработки его персональных данных, в порядке, форме и сроки, установленные законодательством о персональных данных;</li>
                <li className={styles.nestedUnorderedItem}> требовать уточнения своих персональных данных, их блокирования и уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;</li>
                <li className={styles.nestedUnorderedItem}> принимать предусмотренные законом меры по защите своих прав;</li>
                <li className={styles.nestedUnorderedItem}> отозвать свое согласие на обработку персональных данных.</li>
              </ul>
            </li>
            <li className={styles.nestedOrderedItem}>Субъект персональных данных обязан предоставить полные, точные и достоверные сведения о своих персональных данных.</li>
          </ol>
        </li>
        <li className={styles.mainOrderedItem}>
          <span>Права и обязанности компании при обработке персональных данных</span>
          <ol className={styles.nestedList}>
            <li className={styles.nestedOrderedItem}>
              <span>Компания имеет право:</span>
              <ul className={styles.nestedUnorderedList}>
                <li className={styles.nestedUnorderedItem}> обрабатывать персональные данные субъекта персональных данных в соответствии с заявленной целью;</li>
                <li className={styles.nestedUnorderedItem}> требовать от субъекта персональных данных предоставления достоверных персональных данных, необходимых для заключения, исполнения договора, оказания услуги, идентификации субъекта персональных данных, а также в иных случаях, предусмотренных законодательством о персональных данных;</li>
                <li className={styles.nestedUnorderedItem}> ограничить доступ субъекта персональных данных к его персональным данным в случае, если обработка персональных данных осуществляется в соответствии с законодательством о противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма, доступ субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц, а также в иных случаях, предусмотренных законодательством Российской Федерации;</li>
                <li className={styles.nestedUnorderedItem}> обрабатывать общедоступные персональные данные физических лиц;</li>
                <li className={styles.nestedUnorderedItem}> осуществлять обработку персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с законодательством Российской Федерации;</li>
                <li className={styles.nestedUnorderedItem}> уточнять обрабатываемые персональные данные, блокировать или удалять, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;</li>
                <li className={styles.nestedUnorderedItem}> поручить обработку персональных данных другому лицу с согласия субъекта персональных данных.</li>
              </ul>
            </li>
            <li className={styles.nestedOrderedItem}>
              <span>В соответствии с требованиями Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных данных» Компания обязана:</span>
              <ul className={styles.nestedUnorderedList}>
                <li className={styles.nestedUnorderedItem}> предоставлять субъекту персональных данных по его запросу информацию, касающуюся обработки его персональных данных, либо на законных основаниях предоставить отказ;</li>
                <li className={styles.nestedUnorderedItem}> по требованию субъекта персональных данных уточнять обрабатываемые персональные данные, блокировать или удалять, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;</li>
                <li className={styles.nestedUnorderedItem}> уведомлять субъекта персональных данных об обработке персональных данных в том случае, если персональные данные были получены не от субъекта персональных данных за исключением предусмотренных законом Российской Федерации случаев;</li>
                <li className={styles.nestedUnorderedItem}> поручить обработку персональных данных другому лицу с согласия субъекта персональных данных.</li>
              </ul>
            </li>
          </ol>
        </li>
        <li className={styles.mainOrderedItem}>
          <span>Сведения о реализуемых в компании мерах для защиты персональных данных</span>
          <ol className={styles.nestedList}>
            <li className={styles.nestedOrderedItem}>При обработке персональных данных Компания принимает необходимые правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.</li>
            <li className={styles.nestedOrderedItem}>
              Сведения о реализуемых в Компании мерах для защиты персональных данных:
              <ul className={styles.nestedUnorderedList}>
                <li className={styles.nestedUnorderedItem}>
                  <span> В целях недопущения вреда при обработке персональных данных в Компании реализуются следующие меры для защиты персональных данных:</span>
                  <ul className={styles.nestedUnorderedList}>
                    <li className={styles.nestedUnorderedItem}>
                      <span> Правовые меры состоят из:</span>
                      <ul className={styles.nestedUnorderedList}>
                        <li className={styles.nestedUnorderedItem}> Инструктажа всех работников Компании по требованиям действующего законодательства в области персональных данных;</li>
                        <li className={styles.nestedUnorderedItem}> Изучения и применения правовых механизмов, направленных на минимизацию вреда и ущерба субъектам персональных данных;</li>
                        <li className={styles.nestedUnorderedItem}> Разработки, принятия и соблюдения локальных актов по вопросам персональных данных. </li>
                      </ul>
                    </li>
                    <li className={styles.nestedUnorderedItem}>
                      <span> Организационные меры состоят из:</span>
                      <ul className={styles.nestedUnorderedList}>
                        <li className={styles.nestedUnorderedItem}> Систематических разъяснительных мероприятий с работниками Компании по вопросам недопущения причинения вреда субъектам персональных данных;</li>
                        <li className={styles.nestedUnorderedItem}> Подбор квалифицированных кадров;</li>
                        <li className={styles.nestedUnorderedItem}> Возложения обязанности по разъяснению настоящей политики на Ответственных за обработку персональных данных лиц.</li>
                      </ul>
                    </li>
                    <li className={styles.nestedUnorderedItem}>
                      <span> Технические меры включают в себя:</span>
                      <ul className={styles.nestedUnorderedList}>
                        <li className={styles.nestedUnorderedItem}> Обеспечение бесперебойного функционирования технических средств обработки персональных данных. Под техническими средствами, позволяющими осуществлять обработку персональных данных, понимаются средства вычислительной техники, информационно-вычислительные комплексы и сети, средства и системы передачи, приема и обработки персональных данных (средства и системы звукозаписи, звукоусиления, звуковоспроизведения, переговорные и телевизионные устройства, средства изготовления, тиражирования документов и другие технические средства обработки речевой, графической, видео- и буквенно-цифровой информации), программные средства (операционные системы, системы управления базами данных и т.п.), средства защиты информации, применяемые в информационных системах;</li>
                        <li className={styles.nestedUnorderedItem}> Обеспечение физической защиты объектов, эксплуатируемых Компанией;</li>
                        <li className={styles.nestedUnorderedItem}> Обеспечение комфортного морально-психологического климата и обстановки делового сотрудничества среди работников Компании;</li>
                        <li className={styles.nestedUnorderedItem}> Незамедлительное восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним.</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li className={styles.mainOrderedItem}>
          <span>Ответственность сторон</span>.
          <ol className={styles.nestedList}>
            <li className={styles.nestedOrderedItem}>Контроль за выполнением требований настоящей Политики, правил и требований, применяемых при обработке персональных данных в Компании, осуществляют лица, назначенные соответствующими приказами исполнительных органов Компании.</li>
            <li className={styles.nestedOrderedItem}>Компания, а также должностные лица и сотрудники несут ответственность за несоблюдение принципов и условий обработки персональных данных, а также за разглашение или незаконное использование персональных данных в соответствии с законодательством Российской Федерации.</li>
          </ol>
        </li>
        <li className={styles.mainOrderedItem}>
          <span>Заключительное положение</span>
          <ol className={styles.nestedList}>
            <li className={styles.nestedOrderedItem}>Настоящая Политика является внутренним документом Компании, является общедоступной и подлежит размещению на сайте Компании.</li>
            <li className={styles.nestedOrderedItem}>Настоящая Политика вступает в силу с момента утверждения исполнительным органом.</li>
            <li className={styles.nestedOrderedItem}>Настоящая Политика подлежит пересмотру в связи с изменением законодательства Российской Федерации в области обработки и защиты персональных данных, по результатам оценки актуальности, достаточности и эффективности реализуемых в Компании мер для защиты персональных данных.</li>
          </ol>
        </li>
        <li className={styles.mainOrderedItem}>
          <span>Обратная связь</span>
          <div className={styles.address}>Контактные данные для обращения субъектов персональных данных по вопросам, относящимся к персональным данным: Почтовый адрес: г. Пермь, ул. Советская, 51А/2, офис 207.</div>
        </li>
      </ol>
    </div>
  );
}
