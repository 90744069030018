import React, { FormEvent, useRef, useState } from 'react';
import styles from './authorization.module.css';
import backgroundImgWEBP from '../../images/backgroundWEBP.webp';
import backgroundImgPNG from '../../images/backgroundPNG.png';
import { useUnit } from 'effector-react';
import axios from 'axios';
import { $authorizationStore, setAuthorizationStore } from '../../store/authorizationStore';
import { HidePasswordIcon, LogoIcon, ShowPasswordIcon } from '../Icons';
import { PrivacyPolicyPopup } from '../PrivacyPolicyPopup';
import { Popup } from '../Popup';

interface IError {
  code: number;
  text: string;
}

const errorCodeArr: IError[] = [
  {code: 400, text: 'Неверный логин или пароль. Пожалуйста, убедитесь, что правильно ввели логин и пароль.'},
  {code: 403, text: 'Извините, но у вас нет разрешения на доступ к этому ресурсу. Пожалуйста, убедитесь, что вы ввели правильные учетные данные. Если проблема сохраняется, пожалуйста, обратитесь к администратору системы для уточнения прав доступа.'},
  {code: 401, text: 'Не удалось авторизоваться. Пожалуйста, убедитесь, что правильно ввели логин и пароль.'},
  {code: 500, text: 'К сожалению, произошла внутренняя ошибка сервера при попытке авторизации. Мы уже работаем над устранением проблемы. Пожалуйста, попробуйте повторить попытку авторизации позже.'},
];

export function Authorization() {
  const [popupOpen, setPopupOpen] = useState(false);
  const authorizationStore = useUnit($authorizationStore);
  const popupButtonRef = useRef<HTMLButtonElement | null>(null);
  const [error, setError] = useState<IError | null>(null);
  const [loading, isLoading] = useState(false);

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (authorizationStore.loginInput === '' || authorizationStore.passwordInput === '') return;

    isLoading(true);
    axios.post('https://monit.roksperm.ru/auth/login/',
      {
        username: authorizationStore.loginInput,
        password: authorizationStore.passwordInput
      }
    )
    .then((res: any) => {
      if (res.data.detail === 'Successfully logged in.') {
        setError(null);
        isLoading(false);
        window.location.href = 'https://monit.roksperm.ru/';
      }
    })
    .catch(error => {
      if (error.response) {
        const currentError = errorCodeArr.find(el => el.code === error.response.status);
        if (currentError) {
          setError(currentError);
        } else {
          throw error;
        }
      }
    })
    .finally(() => {
      isLoading(false);
    })
  }

  function handleChangeInputValue(event: React.FormEvent<HTMLInputElement>, inputType: string) {
    if (error) {
      setError(null);
    };

    if (inputType === 'login') {
      setAuthorizationStore({...authorizationStore, loginInput: event.currentTarget.value});
      return;
    }

    if (inputType === 'password') {
      setAuthorizationStore({...authorizationStore, passwordInput: event.currentTarget.value});
      return;
    }
  };

  function handlePopupOpen() {
    setPopupOpen(true);
  };

  function handleChangePasswordType() {
    if (authorizationStore.passwordInput === '') return false;
    if (authorizationStore.passwordInputType === 'password') {
      setAuthorizationStore({...authorizationStore, passwordInputType: 'text'});
    }
    if (authorizationStore.passwordInputType === 'text') {
      setAuthorizationStore({...authorizationStore, passwordInputType: 'password'});
    }
  };

  return (
    <div className={styles.auth}>
      <div className={styles.authFormBlock}>
        <div className={styles.authFormBody}>
          <a href="https://monit.roksperm.ru/" className={styles.logo}><LogoIcon />РОКС</a>
          <h2 className={styles.authTitle}>Вход в систему</h2>
          <p className={styles.authText}>Авторизуйтесь, чтобы войти в систему</p>
          <form method='POST' onSubmit={handleSubmit} className={styles.authForm}>
            <input className={styles.authFormInput}
              value={authorizationStore.loginInput}
              onChange={(event) => handleChangeInputValue(event, 'login')} 
              type="text" 
              name='username'
              required
              placeholder='Логин'
            />
            <div className={styles.inputBlock}>
              <input className={styles.authFormInput}
                value={authorizationStore.passwordInput}
                onChange={(event) => handleChangeInputValue(event, 'password')}
                type={authorizationStore.passwordInputType}
                name='password'
                required
                placeholder='Пароль'
              />
              <div className={styles.changeTypeIcon} onClick={handleChangePasswordType}>
                {authorizationStore.passwordInputType === 'password' ? <HidePasswordIcon /> : <ShowPasswordIcon /> }
              </div>
              <div className={styles.privacyPolicy}>
                <button ref={popupButtonRef} onClick={handlePopupOpen} className={styles.privacyPolicyLink} type='button'>Политика конфиденциальности</button>
              </div>
            </div>
            {error &&
              <div className={styles.errorBlock}>
                <div className={styles.errorText}>{error.text}</div>
              </div>
            }
            <div>
              <button className={styles.authFormButton}>{loading
                ? <div className={styles.spinnerWrapper}>
                    <div className={styles.spinner}></div>
                  </div>
                : 'Войти'}
              </button>
            </div>
          </form>
          <Popup buttonRef={popupButtonRef} state={popupOpen} setState={setPopupOpen}><PrivacyPolicyPopup setOpen={setPopupOpen} /></Popup>
        </div>
      </div>
      <div className={styles.authBackground}>
        <picture>
          <source srcSet={backgroundImgWEBP} type='image/webp' />
          <img className={styles.authBackgroundImg} src={backgroundImgPNG} alt='background' />
        </picture>
      </div>
    </div>
  );
}
