import { createEvent, createStore } from "effector";

interface IAuthorizationStore {
  loginInput: string;
  passwordInput: string;
  passwordInputType: string;
}

export const setAuthorizationStore = createEvent<IAuthorizationStore>();
export const $authorizationStore = createStore<IAuthorizationStore>({loginInput: '', passwordInput: '', passwordInputType: 'password'});
$authorizationStore.on(setAuthorizationStore, (_state, data: IAuthorizationStore) => {
  return {...data};
});
  