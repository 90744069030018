import React from 'react';
import styles from './backdrop.module.css';
import { CSSTransition } from 'react-transition-group';

interface IBackdropProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  bgStyle?: string;
  height?: string;
}

export function Backdrop({open, setOpen, bgStyle = 'rgba(0, 0, 0, 0.315)', height = '100dvh'}: IBackdropProps) {
  const nodeRef = React.useRef(null);
  
  const classNames = {
    enter: styles.nodeEnter,
    enterActive: styles.nodeEnterActive,
    exit: styles.nodeExit,
    exitActive: styles.nodeExitActive,
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <CSSTransition
      in={open}
      timeout={300}
      classNames={classNames}
      nodeRef={nodeRef}
      unmountOnExit
    >
      <div onClick={handleClose} ref={nodeRef} className={styles.popup} style={{backgroundColor: bgStyle, height: height}}></div>
    </CSSTransition>
  );
}
