import React from 'react';
import styles from './privacypolicypopup.module.css';
import { PrivacyPolicy } from '../PrivacyPolicy/PrivacyPolicy';

interface IPrivacyPolicyPopupProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PrivacyPolicyPopup({setOpen}: IPrivacyPolicyPopupProps) {
   function handleClose() {
    setOpen(false);
  }

  return (
    <div className={styles.privacyPolicyBody}>
      <div className={styles.contentWrapper}>
        <div className={styles.privacyPolicyContent}><PrivacyPolicy /></div>
      </div>
      <button onClick={handleClose} className={styles.closeButton}>Закрыть</button>
    </div>
  );
}
