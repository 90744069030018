import React, { useEffect, useRef } from 'react';
import styles from './popup.module.css';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { Backdrop } from './Backdrop';
import { blockScrollBar } from '../../utils/js/blockScrollBar';

export interface IPopupProps {
  children: React.ReactNode;
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>;
  bgStyle?: string;
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Popup({children, buttonRef, bgStyle, state, setState}: IPopupProps) {
  const popupRef = useRef<HTMLDivElement | null>(null);

  const classNames = {
    enter: styles.nodeEnter,
    enterActive: styles.nodeEnterActive,
    exit: styles.nodeExit,
    exitActive: styles.nodeExitActive,
  }

  useEffect(() => {
    if (state) {
      blockScrollBar('lock');
    } else {
      blockScrollBar('unlock');
    }
  }, [state])

  useEffect(() => {
    if (!state) return;

    function handleClick(event: MouseEvent) {
      if (event.target === document.querySelector('#ymaps')) return;
      if (event.target instanceof Node && !popupRef.current?.contains(event.target) && !buttonRef.current?.contains(event.target)) {
        setState(false);
      }
    }

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    }
  }, [state]);

  const node = document.querySelector('#modal_root');
  if (!node) return null;

  return (
    createPortal(
      <>
        <Backdrop open={state} setOpen={setState} bgStyle={bgStyle}/>
        <CSSTransition
          in={state}
          timeout={300}
          classNames={classNames}
          nodeRef={popupRef}
          unmountOnExit
        >
          <div className={styles.popup}>
            <div className={styles.popupBody} ref={popupRef}>
              {children}
            </div>
          </div>
        </CSSTransition>
      </>,
    node)
  );
}
