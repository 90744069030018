import React from 'react';

export function LogoIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.7003 41.8962L17.0869 34.3176C10.9944 28.9572 0.700317 32.7465 0.700317 40.325V50.2141H50.7003V19.8999L25.7003 41.8962Z" fill="#3C4BDC"></path>
      <path d="M25.7003 8.53204L34.3138 16.1106C40.4062 21.471 50.7003 17.6818 50.7003 10.1032V0.214111H0.700317V30.5283L25.7003 8.53204Z" fill="#3C4BDC"></path>
    </svg>
  );
}
